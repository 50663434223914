<template>
    <company-profile/>
</template>

<script>
import CompanyProfile from '@components/companies/Profile.vue';

export default {
    name: 'Show',

    components: { CompanyProfile },
};
</script>

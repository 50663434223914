<template>
    <div>
        Company Profile
    </div>
</template>

<script>
export default {
    name: 'Profile',
};
</script>
